import Api from "@/api/apiAxios";
export default {
	getAgentlist:(pageNo:Number,pageSize:Number,params:object)=> Api.postRequest(`/agentInfo/list/${pageNo}/${pageSize}`,params),     //查询代理商
	getAgentLevel:(agentNo:Number)=> Api.postRequest(`/agentInfo/listAgentLevel/${agentNo}`),											    //查看代理商层级
	resetPassword:(agentNo:Number)=> Api.postRequest(`/agentInfo/resetPassword/${agentNo}`),												//重置密码
	saveAgent:(params:object)=> Api.putRequest(`/agentInfo/saveAgent`,params),												    			//新增代理商
	saveOrg:(params:object)=> Api.putRequest(`/agentInfo/saveOrg`,params),															//新增机构
	listUpperOrg:(pageNo:Number,pageSize:Number,params:object)=> Api.postRequest(`/agentInfo/listUpperOrg/${pageNo}/${pageSize}`,params),	//查询一级二级机构
	beforeSaveOneAgent:(agentNo:Number)=> Api.getRequest(`/agentInfo/beforeSaveOneAgent/${agentNo}`),				       					//新增代理商之前获取政策配置
	beforeSaveOrg:(parentAgentNo:Number)=> Api.getRequest(`/agentInfo/beforeSaveOrg/${parentAgentNo}`),				        					//新增机构之前获取政策配置
	agentDetail:(agentNo:Number)=> Api.getRequest(`/agentInfo/detail/${agentNo}`),				        									//详情
	improveAgentInfo:(agentNo:Number,params:object)=> Api.postRequest(`/agentInfo/improveAgentInfo/${agentNo}`,params),				 //代理商补充资料
	updateAgentCashBack:(agentNo:Number,params:object)=> Api.postRequest(`/agentInfo/updateAgentCashBack/${agentNo}`,params),		//修改代理返现
	updateAgentBaseInfo:(agentNo:Number,params:object)=> Api.postRequest(`/agentInfo/updateAgentBaseInfo/${agentNo}`,params),		//修改代理商信息
	updateAgentCost:(agentNo:Number,params:object)=> Api.postRequest(`/agentInfo/updateAgentCost/${agentNo}`,params),				//修改代理成本
	beforeUpdateAgentCost:(agentNo:Number)=> Api.getRequest(`/agentInfo/beforeUpdateAgentCost/${agentNo}`),							//修改成本前
	beforeUpdateAgentActivity:(agentNo:Number)=> Api.getRequest(`/agentInfo/beforeUpdateAgentActivity/${agentNo}`),					//修改返现前
	queryAgentNameList:(agentNo:Number)=> Api.postRequest(`/agentInfo/queryAgentNameList/${agentNo}`),								//获取代理商列表
	getAgentPolicyConfig:()=> Api.getRequest(`/agentInfo/getAgentPolicyConfig`),													//代理商政策配置
	beforeNewAgent:()=> Api.getRequest(`/agentInfo/beforeNewAgent`),
	profitSwitch:(agentNo:Number,status:Number)=> Api.postRequest(`/agentInfo/profitSwitch/${agentNo}/${status}`),
	settleProfitSwitch:(agentNo:Number,status:Number)=> Api.postRequest(`/agentInfo/settleProfitSwitch/${agentNo}/${status}`),
	cashbackSwitch:(agentNo:Number,status:Number)=> Api.postRequest(`/agentInfo/cashbackSwitch/${agentNo}/${status}`),
	agentCostTemplate:{
		...Api.generalAPI('agentCostTemplate'),
		getCostTemplate:() =>Api.getRequest(`/agentCostTemplate/beforeUpdateAgentTemplate/`),
		beforeCostSave:() =>Api.getRequest(`/agentCostTemplate/beforeSave`)
	},
	agentActivityTemplate:{
		...Api.generalAPI('agentActivityTemplate'),
		getActivityTemplate:() =>Api.getRequest(`/agentActivityTemplate/beforeUpdateAgentTemplate/`),
		beforeActivitySave:() =>Api.getRequest(`/agentActivityTemplate/beforeSave`)
	},
	agentAdjustCost:{
		...Api.generalAPI('agentAdjustCost'),
		queryCurrentAdjustCost:(adjustNo:String,params:object) =>Api.postRequest(`/agentAdjustCost/queryCurrentAdjustCost/${adjustNo}`,params),
	},
	listProvinceCity: () => Api.getRequest(`/city/listProvinceCity`),						//获取省市
};
