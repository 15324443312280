<template>
    <div>
		<el-button-group>
			<el-button size="mini" :disabled="this.language === 'en'" @click="choiceLanguage('en')">English</el-button>
			<el-button size="mini" :disabled="this.language === 'zh'" @click="choiceLanguage('zh-CN')">中文</el-button>
		</el-button-group>
	</div>
</template>

<script>
	import {mapActions,mapState} from 'vuex';
    export default {
        name: "SwitchLanguage",
		computed: {
			...mapState('app', ['language']),
		},
		methods: {
			...mapActions('app', ['choiceLanguage']),
		}
    }
</script>

<style scoped>

</style>
