<template lang="pug">
	div
		SvgIcon.screenfull-svg(:icon-class="isFullscreen?'exit-fullscreen':'fullscreen'" @click="click")
</template>

<script>
	import SvgIcon from '@/components/SvgIcon'
	import screenfull from 'screenfull'
	export default {
		name: 'ScreenFullMode',
		components: {
			SvgIcon
		},
		data() {
			return {
				isFullscreen: false
			}
		},
		mounted() {
			this.init()
		},
		beforeDestroy() {
			this.destroy()
		},
		methods: {
			click() {
				if (!screenfull.isEnabled) {
					this.$message({
						message: 'you browser can not work',
						type: 'warning'
					});
					return false
				}
				screenfull.toggle()
			},
			change() {
				this.isFullscreen = screenfull.isFullscreen
			},
			init() {
				if (screenfull.isEnabled) {
					screenfull.on('change', this.change)
				}
			},
			destroy() {
				if (screenfull.isEnabled) {
					screenfull.off('change', this.change)
				}
			}
		}
	}
</script>

<style scoped>

	.screenfull-svg {
		position: relative;
		top: 10px;
		display: inline-block;
		cursor: pointer;
		fill: #5a5e66;;
		width: 20px;
		height: 20px;
		vertical-align: 10px;
	}
</style>
