import Api from "@/api/apiAxios";
export default {
	myinfo: {
		listMyAccount: (params: object) =>
			Api.postRequest(`/api/myInfo/listMyAccount`, params), //账户信息
		listMyAccount2: (params: object) =>
			Api.postRequest(`/api/myInfo/listMyAccount2`, params), //账户信息
		queryMyInfo: (params: object) =>
			Api.getRequest(`/agentInfo/myInfoDetail`, params), //我的信息
		updateSetter: (params: object) =>
			Api.postRequest(`/api/myInfo/updateSetter`, params) //修改结算卡
	},
	agentAccountDetail: {
		...Api.generalAPI("agentAccountDetail")
	},
	extractionOrder: {
		queryPage: (pageNo: Number, pageSize: Number, params: object) =>
			Api.postRequest(
				`/extractionOrder/queryPage/${pageNo}/${pageSize}`,
				params
			), //查询提现订单
		withdrawal: (params: object) =>
			Api.postRequest(`/extractionOrder/withdrawal`, params), //提现操作
		beforeWithdrawal: (params: object) =>
			Api.postRequest(`/extractionOrder/beforeWithdrawal`, params) //提现前操作
	},
	execlDownloadInfo: {
		...Api.generalAPI("execlDownloadInfo"),
		put: (params: {}) => Api.putRequest(`/execlDownloadInfo/save`, params),
		reDownload: (params: {}) => Api.putRequest(`/execlDownloadInfo/reDownload`, params),
		// 保存分润明细excel异步导出
		postExeclDownloadInfo: data => Api.postRequest(`/execlDownloadInfo/save`, data),
		// 导出代理商查询列表
		exportAgentInfoAgent: data => Api.postRequest(`/agentInfo/agent/export`, data),
	},
	acqChannel: {
		// 查询所有上游通道信息
		listAllChannel: () => Api.getRequest("/acqChannel/listAllChannel")
	},
	// 代理商查询配置详情
	configDetail: {
		// 代理商查询配置详情
		getConfigDetail: (agentNo, policyNo) => Api.getRequest(`agentAdjustProfitConfig/configDetail/${agentNo}/${policyNo}`),
		// editSaveConfig: (agentNo, policyNo, data) => Api.putRequest(`agentAdjustProfitConfig/saveConfig/${agentNo}/${policyNo}`, data)
		editSaveConfig: (data) => Api.putRequest(`agentAdjustProfitConfig/saveConfig`, data)
	}
};
