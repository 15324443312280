/**
 * @author kco1989
 * @email kco1989@qq.com
 * @date 2019-11-30 13:59
 */
import {routerPath} from '../tool';
import Layout from '@/views/layout/Layout.vue'


export default {
    path: '/test',
    name: 'test',
    component: Layout,
    meta: {
        title: '测试页面'
    },
    children: [
        // test1
        routerPath('test', 'testFishCount', "数鱼"),
        routerPath('test', 'testUrtc', "测试Urtc"),
        routerPath('test', 'testBpmn', "测试Bpmn"),
        routerPath('test', 'testBpmn2', "测试Bpmn2"),
        routerPath('test', 'test1', "测试页面1"),
        routerPath('test', 'testWebsocket', "测试Websocket"),
        routerPath('test', 'testUploadImage2Sso', "测试上传图片"),
        // test2
        routerPath('test', 'test2', "测试页面2"),
        routerPath('test', 'testMessage', "测试消息"),
        routerPath('test', 'testNotice', "测试通知提醒"),
		{
			path: `/test/guide`,
			meta: {
				title: '向导',
				tabView: true
			},
			component: () => import(`@/views/guide/Guide.vue`)
		}
    ]
};
