/**
 * @author kco1989
 * @email kco1989@qq.com
 * @date 2019-12-06 15:17
 */
export const TEST_INCREMENT = 'TEST_INCREMENT';

let state = {
    count: 0,
    todos: [
        {id: 1, text: 'to do something1', done: true},
        {id: 1, text: 'to do something2', done: false}
    ]
};
let mutations = {
    [TEST_INCREMENT] (state:any){
        state.count++;
    }
};
let getters = {
    doneTodos: (state:any) => {
        return state.todos.filter((todo:any) => todo.done).length
    }
};
export default {
    state,
    mutations,
    getters
};