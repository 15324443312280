import translations from "@/plugins/i18n/translations";
import store from "@/store";
import {GET_LANGUAGE, GET_LOGIN_TOKEN} from "@/store/constants";

/**
 * @author kco1989
 * @email kco1989@qq.com
 * @date 2019-12-23 17:54
 */
export function guid() {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = Math.random() * 16 | 0,
			v = c == 'x' ? r : (r & 0x3 | 0x8);
		return v.toString(16);
	});
}
export function deepAssign(target:any, ...sources:any) {
	// 1. 参数校验
	if (target == null) {
		throw new TypeError('Cannot convert undefined or null to object');
	}

	// 2. 如果是基本类型数据转为包装对象
	let result = Object(target);

	// 3. 缓存已拷贝过的对象，解决引用关系丢失问题
	if (!result['__hash__']) {
		result['__hash__'] = new WeakMap();
	}
	let hash  = result['__hash__'];

	sources.forEach((v:any) => {
		// 4. 如果是基本类型数据转为对象类型
		let source = Object(v);
		// 5. 遍历原对象属性，基本类型则值拷贝，对象类型则递归遍历
		Reflect.ownKeys(source).forEach(key => {
			// 6. 跳过自有的不可枚举的属性
			let ownPropertyDescriptor = Object.getOwnPropertyDescriptor(source, key);
			if (ownPropertyDescriptor && !ownPropertyDescriptor.enumerable) {
				return;
			}
			if (typeof source[key] === 'object' && source[key] !== null) {
				// 7. 属性的冲突处理和拷贝处理
				let isPropertyDone = false;
				if (!result[key] || !(typeof result[key] === 'object')
					|| Array.isArray(result[key]) !== Array.isArray(source[key])) {
					// 当 target 没有该属性，或者属性类型和 source 不一致时，直接整个覆盖
					if (hash.get(source[key])) {
						result[key] = hash.get(source[key]);
						isPropertyDone = true;
					} else {
						result[key] = Array.isArray(source[key]) ? [] : {};
						hash.set(source[key], result[key]);
					}
				}
				if (!isPropertyDone) {
					result[key]['__hash__'] = hash;
					deepAssign(result[key], source[key]);
				}
			} else {
				Object.assign(result, {[key]: source[key]});
			}
		});
	});

	delete result['__hash__'];
	return result;
}
function linkGet(obj:any, key:string) {
	obj = deepAssign({}, obj || {});
	let paths = key && key.split(".") || [];
	while(paths.length>0){
		let path = paths && paths.shift();
		obj=obj && obj[path || ''];
	}
	return obj;
}
/**
 * 国际化翻译
 * @param template
 * @param replacements
 */
export function translate(template: string, replacements: any = {}) {
	replacements = replacements || {};
	let trans = translations[store.getters[GET_LANGUAGE]];
	// Translate
	template = linkGet(trans, template) || template;
	// Replace
	return template.replace(/{([^}]+)}/g, function (_, key) {
		return linkGet(trans, replacements[key]) || replacements[key] || '{' + key + '}';
	});
}

/**
 * 字典值翻译
 * @param value
 * @param options
 */
export function dictTranslate(value:string, options:any) {
	return options && options[value] || value;
}

/**
 * 下载文件
 * @param fileName
 */
export function downloadFile(fileName:string) {
	window.location.href = `${process.env.VUE_APP_BASE_API}/common/download?fileName=${encodeURI(fileName)}&delete=true&LOGIN_TOKEN=${store.getters[GET_LOGIN_TOKEN]}`;
}


export function filterObj(obj:Object, arr: Array<String>) {
	if(!obj){
		return
	}
    if (typeof (obj) !== "object" || !Array.isArray(arr)) {
        throw new Error("参数格式不正确")
    }
    const result = {}
    Object.keys(obj).filter((key) => arr.includes(key)).forEach((key) => {
        result[key] = obj[key]
    })
    return result
}

/**
 * 截取年月日
 */
export function formatOCRDate(val:String){
	if(val.indexOf('年')>-1){
		return val.substring(0,4) + '-' + val.substring(5,7) + '-' + val.slice(-3,-1)
	} else{
		return val.substring(0,4) + '-' + val.substring(4,6) + '-' + val.slice(-2)
	}
}

export function accAdd(arg:any,digit:any){ 
	var m = Math.pow(10, digit); 
	var num = arg * m
    return parseInt(num.toString(), 10) / m; 
} 

/**逗号格式化 */
export function replaceComma(e) {
	e.target.value && (e.target.value = e.target.value.replace(new RegExp('，', 'gm'), ','))
	e.target.dispatchEvent(new Event('input'))
  }