export default {
    "COMMON": {
        "CREATE_BY": "创建人",
        "CREATE_TIME": "创建时间",
        "UPDATE_BY": "更新人",
        "SORT": "排序",
        "UPDATE_TIME": "更新时间",
        "LAST_UPDATE_TIME": "最后更新时间",
        "REMARK": "备注",
        "START_DATE": "开始时间",
        "END_DATE": "开始时间",
        "OPERATION": "操作",
        "SERVER_TIMEOUT": "服务超时,请稍后重试",
        "SERVER_EXCEPTION": "服务异常,请稍后重试",
        "HOME": "首页",
        "REFRESH": "刷新",
        "CLOSE": "关闭",
        "CLOSE_OTHER": "关闭其他",
        "CLOSE_ALL": "关闭所有",
        "IMAGE_CONTROL_TYPES": "The image type of the ossupload control can only be of the following types",
        "USER_PROFILE": "用户配置",
        "BAI_DU": "百度",
        "KEYWORD": "关键字",
        "ALL": "全部",
        "OPERATION_SUCCESS": "操作成功",
        "STATUS": "状态",
        "IMAGE_CONTROL_LIMIT": "最多只能上载{param}个文件",
        "IMAGE_CONTROL_SUFFIX": "上传文件的后缀只能是{param}格式！",
        "UPLOAD_FILE_CONTROL_SIZE": "上载文件大小不能超过{param}MB！"
    },
    "MODULE": {
        "CONFIG": "配置",
        "DICT": "字典",
        "AUTHORITY": "权限",
        "LOGIN_INFO": "登陆信息",
        "OPER_LOG": "操作日志",
        "ROLE": "角色",
        "MENU": "菜单",
        "USER": "用户",
        "THIRD_USER_LOGIN": "登陆方式"
    },
    "BPMN": {
        "SHORTCUT_KEYS": "快捷键",
        "BPMN_DIAGRAM": "设计图",
        "SVG_IMAGE": "svg图",
        "UNDO": "撤消",
        "REDO": "重做",
        "RESET_ZOOM": "还原",
        "ZOOM_OUT": "放大",
        "ZOOM_IN": "缩小"
    },
    "FROM": {
        "PLACEHOLDER": "请输入{param}",
        "SUBMIT": "提交",
        "CANCEL": "取消",
        "ADD_MODULE": "添加{module}",
        "UPDATE_MODULE": "修改{module}",
        "ADD_SUCCESS": "添加成功",
        "UPDATE_SUCCESS": "修改成功",
        "DELETE_SUCCESS": "删除成功",
        "WARNING": "警告",
        "CONFIRM_EXPORT": "是否确定导出 {module} 的数据项?",
        "CONFIRM_DELETE": "是否确定删除{module}编号为{id}的数据项?",
        "CONFIRM_OPERATION": "是否确定删除{module}编号为{id}的数据项?",
        "DISABLE": "禁用",
        "ENABLE": "启用"
    },
    "OPERATION": {
        "QUERY": "查询",
        "RESET": "重置",
        "ADD": "添加",
        "EXPORT": "导出",
        "UPDATE": "修改",
        "DELETE": "删除"
    },
    "LOGIN": {
        "LOGIN_TITLE": "代理商登陆",
        "LOGOUT": "退出",
        "LOGIN_NAME": "登陆用户",
        "PASSWORD": "密码",
        "IMAGE_VERIFICATION_CODE": "短信校验码",
        "LOGIN_BUTTON": "登陆",
        "LOGIN_NAME_OR_PASSWORD": "用户名或密码",
        "FORCE_LOGOUT_SUCCESS": "强制注销成功"
    },
    "CONFIG": {
        "ID": "id",
        "CODE": "编码",
        "VALUE": "值",
        "DESCRIPTION": "描述",
        "STATUS": "状态"
    },
    "DICT": {
        "ID": "id",
        "PARENT_ID": "父编码",
        "ANCESTORS": "祖级列表",
        "CODE": "编码",
        "NAME": "名称",
        "VALUE": "值",
        "SORT": "排序"
    },
    "SEQUENCE": {
        "ID": "id",
        "NAME": "名称",
        "PREFIX": "id前缀",
        "DESCRIPTION": "描述",
        "CURRENT_NUM": "当前id",
        "NUM_LENGTH": "id长度",
        "INCREMENT": "每次递增数"
    },
    "AUTHORITY": {
        "AUTH_ID": "权限id",
        "GROUP_CODE": "组code",
        "MODULE": "模块",
        "CODE": "权限码",
        "NAME": "名称",
        "MENU_ID": "菜单id",
        "TYPE": "权限类型"
    },
    "DEPARTMENT": {
        "DEPT_ID": "部门id",
        "ID": "id",
        "PARENT_ID": "上级部门",
        "ANCESTORS": "祖级列表",
        "DEPT_NAME": "部门名称",
        "SORT": "显示顺序",
        "LEADER_ID": "负责人id",
        "PHONE": "联系电话",
        "EMAIL": "邮箱",
        "STATUS": "部门状态",
        "DEL_FLAG": "删除标志"
    },
    "LOGIN_INFO": {
        "ID": "访问id",
        "SESSION_ID": "会话id",
        "USER_ID": "登录账号id",
        "IDENTITY_ID": "第三登陆id",
        "LOGIN_TYPE": "登陆类型",
        "LOGIN_TIME": "登陆时间",
        "PLATFORM": "登陆平台",
        "IP_ADDR": "登录IP地址",
        "LOCATION_ID": "登录地点ID",
        "LOCATION": "登录地点",
        "BROWSER": "浏览器类型",
        "OS": "操作系统",
        "STATUS": "部门状态"
    },
    "MENU": {
        "MENU_ID": "菜单id",
        "ID": "id",
        "NAME": "菜单名称",
        "PARENT_ID": "父菜单ID",
        "SORT": "显示顺序",
        "URL": "请求地址",
        "TARGET": "打开方式",
        "STATUS": "状态",
        "ICON": "菜单图标"
    },
    "OPER_LOG": {
        "ID": "主键",
        "TITLE": "模块标题",
        "BUSINESS_TYPE": "业务类型",
        "METHOD": "方法名称",
        "USER_ID": "用户id",
        "USER_NAME": "用户昵称",
        "URL": "请求URL",
        "IP": "主机地址",
        "LOCATION_ID": "操作地点",
        "LOCATION": "登录地点",
        "SYSTEM": "操作系统",
        "BROWSER": "操作浏览器",
        "APP_INFO": "请求头",
        "PARAMS": "请求参数",
        "RESULT": "返回内容",
        "STATUS": "操作状态",
        "MESSAGE": "错误消息",
        "COST_TIME": "耗时"
    },
    "POST": {
        "POST_ID": "岗位ID",
        "ID": "id",
        "PARENT_ID": "上级岗位",
        "ANCESTORS": "祖级列表",
        "DEPT_ID": "所属部门",
        "POST_NAME": "岗位名称",
        "SORT": "显示顺序",
        "STATUS": "状态"
    },
    "POST_ROLE": {
        "ID": "id",
        "POST_ID": "岗位id",
        "ROLE_ID": "角色ID"
    },
    "ROLE": {
        "ROLE_ID": "角色Id",
        "ID": "角色Id",
        "NAME": "名称",
        "ROLE_CODE": "角色key",
        "SORT": "显示顺序",
        "STATUS": "状态",
        "DEL_FLAG": "删除标志"
    },
    "ROLE_AUTHORITY": {
        "ID": "id",
        "ROLE_ID": "角色key",
        "AUTH_CODE": "权限码"
    },
    "ROLE_MENU": {
        "ID": "id",
        "ROLE_ID": "角色ID",
        "MENU_ID": "菜单ID",
        "FOR_ROLE": "为角色",
        "ASSIGNED_MENU": "分配菜单和权限",
        "MENU_TREE": "菜单树",
        "SHOW_MENU": "展示菜单",
        "MENU_AUTHORITY": "菜单权限"
    },
    "THIRD_USER_AUTH": {
        "ID": "id",
        "USER_ID": "用户id",
        "IDENTITY_TYPE": "授权类型",
        "CREDENTIAL": "授权码",
        "NICKNAME": "用户昵称",
        "AVATAR": "头像路径",
        "SEX": "性别",
        "LAST_LOGIN_TIME": "最后登录时间",
        "LAST_LOGIN_IP": "最后登录IP",
        "ACCESS_TOKEN": "access token",
        "REFRESH_TOKEN": "refresh token"
    },
    "USER": {
        "USER_ID": "用户id",
        "ID": "id",
        "NICKNAME": "昵称",
        "LOGIN_NAME": "用户名",
        "PHONE": "手机号码",
        "EMAIL": "邮箱",
        "PASSWORD": "密码",
        "SALT": "盐加密",
        "SEX": "性别",
        "AVATAR": "头像路径",
        "STATUS": "状态",
        "USER_TYPE": "用户类型",
        "NEED_MODIFY_PASSWORD": "需要修改密码",
        "DEL_FLAG": "删除标志",
        "FORCE_LOGOUT_TIP": "您确定要强制注销用户编号{param}吗？",
        "ALL_FORCE_LOGOUT_TIP": "您确定要强制注销所有用户编号{param}吗？"
    },
    "USER_POST": {
        "ID": "岗位ID",
        "USER_ID": "用户ID",
        "POST_ID": "岗位ID"
    },
    "USER_ROLE": {
        "ID": "id",
        "USER_ID": "用户id",
        "ROLE_ID": "角色id",
        "FOR_USER": "为用户",
        "ASSIGN_ROLE": "分配角色",
        "UNASSIGNED_ROLE": "回收角色"
    },
    "MENU_TREE": {
        "USER_AUTHORITY": "用户权限配置",
        "USER_MANAGE": "用户管理",
        "ROLE_MANAGE": "角色管理",
        "AUTHORITY_MANAGE": "权限管理",
        "MENU_MANAGE": "菜单管理",
        "OPER_LOG_MANAGE": "操作日志查询",
        "LOGIN_INFO_MANAGE": "登陆日志查询",
        "BASIC_CONFIG": "基础配置",
        "DICT_MANAGE": "字典管理",
        "CONFIG_MANAGE": "环境变量管理",
        "DEV_MANAGE": "开发管理",
        "DEV_DOC_MANAGE": "开发文档",
        "SWAGGER_DOC_MANAGE": "swagger开发文档",
        "HOME": "首页",
        "USER_ASSIGNED_ROLE": "分配角色",
        "USER_ASSIGNED_AUTHORITY": "分配权限"
    }
}