import Api from "@/api/apiAxios";
export default {
	getMerchantlist:(pageNo:Number,pageSize:Number,params:object)=> Api.postRequest(`/merchantInfo/queryPage/${pageNo}/${pageSize}`,params),     //查询商户
	getMerchantDetail:(merchantNo:string)=> Api.getRequest(`/merchantInfo/queryMerInfo/${merchantNo}`),    										 //查询商户详情
	checkEdit:(merchantNo:Number,params:object)=> Api.postRequest(`/merchantInfo/checkEdit/${merchantNo}`,params),     			 				//商户审核修改
	save:(params:object)=> Api.putRequest(`/merchantInfo/save`,params),     	 																//新增商户
	activitySelectList:()=> Api.postRequest(`/merchantInfo/activitySelectList`),     	 														//查询商户活动
	// queryAgentRankInfo:(agentNo:Number)=> Api.postRequest(`/merchantInfo/queryAgentRankInfo/${agentNo}`),     	 								 //代理商层级信息
	merChangeApply:{
		queryPage:(pageNo: Number,pageSize: Number,params: {})=> Api.postRequest(`/merChangeApply/queryPage/${pageNo}/${pageSize}`,params),      //查询商户变更申请记录
		queryChangeApplyDetail:(applyNo: Number,params: {})=> Api.postRequest(`/merChangeApply/queryChangeApplyDetail/${applyNo}`,params),       //查询商户变更申请记录详情
	},
	merStatusChangeApplyDetail:{
		queryMerStatusChangeApply:(merchantNo: Number)=> Api.postRequest(`/merStatusChangeApplyDetail/queryMerStatusChangeApply/${merchantNo}`),       //根据商户编号查询商户状态信息
		merStatusChangeApply:(merchantNo: Number,params: {})=> Api.postRequest(`/merStatusChangeApplyDetail/merStatusChangeApply/${merchantNo}`,params),       //商户状态变更申请
	},
	merSettleChangeApplyDetail:{
		queryMerSettleChangeApply:(merchantNo: Number)=> Api.postRequest(`/merSettleChangeApplyDetail/queryMerSettleChangeApply/${merchantNo}`),       //根据商户编号查询商户状态信息
		merSettleChangeApply:(merchantNo: Number,params: {})=> Api.postRequest(`/merSettleChangeApplyDetail/merSettleChangeApply/${merchantNo}`,params),       //商户状态变更申请
	},
	merRateChangeApplyDetail:{
		queryMerRateChangeApply:(merchantNo: Number)=> Api.postRequest(`/merRateChangeApplyDetail/queryMerRateChangeApply/${merchantNo}`),       //根据商户编号查询商户状态信息
		merRateChangeApply:(merchantNo: Number,params: {})=> Api.postRequest(`/merRateChangeApplyDetail/merRateChangeApply/${merchantNo}`,params),       //商户状态变更申请
	},
	getAuditMerchantlist:(pageNo:Number,pageSize:Number,params:object)=> Api.postRequest(`/merchantInfo/platform/queryPage/${pageNo}/${pageSize}`,params),     //查询平台进件商户
	queryMerInfo:(merchantNo:Number)=> Api.getRequest(`/merchantInfo/platform/queryMerInfo/${merchantNo}`),     					//查询平台进件商户详细信息
	adjustTaskConfigApply:{
		audit:(id:Number,params:{})=> Api.postRequest(`/adjustTaskConfigApply/audit/${id}`,params),
		detail:(id:Number)=> Api.postRequest(`/adjustTaskConfigApply/detail/${id}`),
		edit:(id:Number,params:{})=>Api.postRequest(`/adjustTaskConfigApply/edit/${id}`,params),
		export:(id:Number,params:{})=> Api.postRequest(`/adjustTaskConfigApply/export`),
		list:(pageNo: Number,pageSize: Number,params: {})=>Api.postRequest(`/adjustTaskConfigApply/list/${pageNo}/${pageSize}`,params),
		remove:(id:Number)=> Api.deleteRequest(`/adjustTaskConfigApply/remove/${id}`),
		save:(params:{})=> Api.putRequest(`/adjustTaskConfigApply/save`,params)
	}
};
