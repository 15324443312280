import Api from "@/api/apiAxios";
export default {
	terminalInfo: {
		...Api.generalAPI("terminalInfo"),
		snBatchIssue: (params: Object) =>
			Api.postRequest(`/terminalInfo/snBatchIssue`, params),
		snIssue: (params: Object) =>
			Api.postRequest(`/terminalInfo/snIssue`, params),
		snBatchRecover: (params: Object) =>
			Api.putRequest(`/terminalInfo/snBatchRecover`, params),
		snRecover: (params: {}) =>
			Api.postRequest(`/terminalInfo/snRecover`, params),
		queryMerNameList: (merchantNo: Number) =>
			Api.postRequest(`/terminalInfo/queryMerNameList/${merchantNo}`),
		bindSn: (params: {}) => Api.postRequest(`/terminalInfo/bindSn`, params),
		save: (params: {}) => Api.postRequest(`/terminalInfo/save`, params),
		removeBatch: (params: {}) =>
			Api.deleteRequest(`/terminalInfo/removeBatch`, params),
		remove: (params: {}) =>
			Api.deleteRequest(`/terminalInfo/remove`, params),
		queryMerchantName: (merchantNo: String) =>
			Api.postRequest(`/merchantInfo/queryMerchantName/${merchantNo}`),
		updateActivityBatch: (params: {}) =>
			Api.postRequest(`/terminalInfo/updateActivityBatch`, params),
		updateActivity: (sn: Number, activityNo: Number) =>
			Api.postRequest(`/terminalInfo/updateActivity/${sn}/${activityNo}`),
		// 获取机具操作日志列表
		getTerminalList: (pageNo: Number, pageSize: Number, params: Object) =>
			Api.postRequest(
				`/terminalInfo/queryTerminalOperRecord/${pageNo}/${pageSize}`,
				params
			),
		// 获取机具操作记录详情
		getTerminalDetailList: (
			pageNo: Number,
			pageSize: Number,
			batchNo: any,
			params: Object
		) =>
			Api.postRequest(
				`terminalInfo/queryTerminalOperRecordDetail/${pageNo}/${pageSize}/${batchNo}`,
				params
			)
	},
	hardware: {
		getHardwareList: () => Api.getRequest(`/hardwareInfo/hardwareList`)
	},
	queryInfo: {
		getActivityInfo: () => Api.getRequest(`/activityInfo/activityList`),
		queryByChannelNo: (channelNo: String) => Api.getRequest(`/activityInfo/queryByChannelNo/${channelNo}`),
		getPolicyList: () => Api.postRequest(`/policyInfo/policyList`),
		getOwnPolicyList: () => Api.postRequest(`/policyInfo/ownPolicyList`),
		getOwnActivityList: () =>
			Api.postRequest(`/activityInfo/ownActivityList`)
	},
	acqChannel: {
		// 查询所有上游通道信息
		listAllChannel: () => Api.getRequest("/acqChannel/listAllChannel")
	}
};
