const files = require.context('.', false, /\.ts$/);
const isTestDebug = process.env.VUE_APP_TEST_DEBUG === 'true';
const translations: any[] = [];
files.keys().forEach((key) => {
	if (key === './index.ts') return;
	if (key === './test.ts' && !isTestDebug) return;
	const vuexObj = files(key);

	let lang = key.replace(/\.\/(.*?)\.ts/, "$1");
	// @ts-ignore
	translations[lang] = vuexObj.default;
});
export default translations;
