import {UAParser} from 'ua-parser-js';
const uaParser = new UAParser();

const SET_USER_AGENT = `SET_USER_AGENT`;
const SET_MOBILE = `SET_MOBILE`;

const state = {
    browser: '',
    os: '',
    isBrowser: false,
    isWechat: false,
	isMobile: false,
};

const mutations = {
    [SET_USER_AGENT]: (state:any, userAgent:UAParser.IResult) => {
        state.browser = userAgent.browser.name && userAgent.browser.name.toLowerCase();
        state.os = userAgent.os.name && userAgent.os.name.toLowerCase();
        const regExp = new RegExp(/MicroMessenger/i);
        if (regExp.test(userAgent.ua)) {
            state.isBrowser = false;
            state.isWechat = true;
        }else {
            state.isWechat = false;
            state.isBrowser = true;
        }
    },
	[SET_MOBILE]: (state:any, isMobile: boolean) => {
		state.isMobile = isMobile;
	}

};

let actions = {
    getUserAgent: ({commit}: any) => {
        const result:UAParser.IResult = uaParser.getResult();
        commit(SET_USER_AGENT, result);
    },
    setMobile: ({commit}: any, isMobile: boolean) => {
		commit(SET_MOBILE, isMobile);
	}
};

export default {
    state,
    mutations,
    actions
}
