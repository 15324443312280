import {routerPath, routerPathA} from '../tool';
import Layout from '@/views/layout/Layout.vue'

export default {
    path: '/profitManage',
    name: 'profitManage',
    component: Layout,
    meta: {
        title: '分润管理',
        hasIndex: true,
		tabView: true
    },
    children: [
        routerPathA('profitManage', 'myProfitAccount', '我的分润账户',false),
        routerPathA('profitManage', 'cashbackRecord', '提现记录',false),
        routerPathA('profitManage', 'execlDownload', '分润明细下载',false),
        {
			path: `/profitManage/myAccount`,
			meta: {
				title: '我的账户',
				tabView: true,
                noCache: false
			},
			component: () => import(`@/views/profitManage/myAccount/index.vue`)
		}
    ]
};