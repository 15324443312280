
import {routerPathA} from '../tool';
import Layout from '@/views/layout/Layout.vue'

export default {
    path: '/myAgent',
    name: 'myAgent',
    component: Layout,
    meta: {
        title: '代理商管理',
        hasIndex: true,
		tabView: true
    },
    children: [
        routerPathA('myAgent', 'agentManage', '代理商查询',false),
        routerPathA('myAgent', 'orgManage', '机构查询',false),
        routerPathA('myAgent', 'orgInsert', '新增机构'),
        routerPathA('myAgent', 'agentInsert', '新增代理商'),
        routerPathA('myAgent', 'agentDetail', '详情'),
        routerPathA('myAgent', 'orgDetail', '详情'),
        routerPathA('myAgent', 'agentUpdate', '补充资料'),
        routerPathA('myAgent', 'orgUpdate', '补充资料'),
        routerPathA('myAgent', 'benefitConfig', '分润配置'),
        routerPathA('myAgent', 'cashBackConfig', '返现配置'),
        routerPathA('myAgent', 'businessAgentAdjustCost', '调价配置'),
    ]
};
