
import {routerPathA} from '../tool';
import Layout from '@/views/layout/Layout.vue'

export default {
    path: '/myMerchant',
    name: 'myMerchant',
    component: Layout,
    meta: {
        title: '我的商户',
        hasIndex: true,
		tabView: true
    },
    children: [
        routerPathA('myMerchant', 'merchantManage', '商户管理',false),
        routerPathA('myMerchant', 'merchantInsert', '新增商户'),
        routerPathA('myMerchant', 'merchantDetail', '商户详情'),
        routerPathA('myMerchant', 'merchantUpdate', '审核修改'),
        routerPathA('myMerchant', 'merchantChangeApply', '商户变更申请',false),
        routerPathA('myMerchant', 'RateChangeApply', '费率变更申请'),
        routerPathA('myMerchant', 'RateChangeDetail', '费率变更申请详情'),
        routerPathA('myMerchant', 'SettleChangeApply', '结算卡变更申请'),
        routerPathA('myMerchant', 'SettleChangeDetail', '结算卡变更申请'),
        routerPathA('myMerchant', 'StatusChangeApply', '商户状态变更申请'),
        routerPathA('myMerchant', 'auditMerchantManage', '审核商户管理',false),
        routerPathA('myMerchant', 'auditMerchantDetail', '审核商户详情'),
    ]
};
